export const app_title = 'ezyERP Food';
export const app_for = 'Restaurant';
export const api_base = 'https://admin.in.ezyerp.net/api/v1';
export const mobile = '919847129911';
export const currency = 'INR';
export const metaTitle = (title = '') => {
    if(title !== ''){
        title = ' | '+title;
    }
    return "ezyERP Food "+title;
}